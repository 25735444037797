import styled from "@emotion/styled"
import { Link } from "gatsby"
import { TransparentText } from "../../styled-components/transparent-text"
import MenuLogo from "../../images/menu-logo.jpg"

export const MenuLink = styled(Link)`
  font-family: "Yantramanav", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  -webkit-text-stroke: 2px #2497c6;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  line-height: 0.85;
  color: transparent;
  margin: 0;
  font-size: 2rem;
  transition: all 0.4s ease-in-out;

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }

  :hover {
    cursor: pointer;
    color: #2497c6 !important;
    -webkit-text-stroke: 2px transparent;
    -webkit-text-fill-color: #2497c6 !important;
  }
`

export const MenuMainMenuItem = styled.li`
  counter-increment: menu-items;
  position: relative;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }

  ${TransparentText} {
    font-size: 3rem;
    transition: all 0.4s ease-in-out;

    @media (min-width: 768px) {
      font-size: 4rem;
    }

    @media (min-width: 1280px) {
      font-size: 5rem;
    }
  }

  :last-of-type {
    margin-bottom: 0;
  }

  :before {
    display: block;
    content: "0" counter(menu-items);
    position: absolute;
    left: -2rem;
    top: 0.5rem;
    color: #fefefe;
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 700;

    @media (min-width: 1024px) {
      top: 1rem;
    }
  }

  :after {
    display: block;
    content: "";
    height: 4px;
    width: 0;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #2497c6;
    transition: all 0.4s ease-in-out;
    pointer-events: none;
  }

  :hover {
    ${TransparentText} {
      -webkit-text-fill-color: #fefefe;
    }
    :after {
      width: 100%;
    }
  }
`

export const MenuRight = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const MenuMainMenu = styled.ul`
  counter-reset: menu-items;
  list-style: none;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 4rem 0;
  margin: 0;
  transform: translate(0, 101%);
  transition: all 0.4s ease-in-out;

  @media (max-width: 767px) {
    padding-left: 2rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 0;
  }
`

export const MenuDrilldownBack = styled.button`
  border: 2px solid #1d5f83;
  background: transparent;
  color: #fefefe;
  padding: 1rem 3rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 700;
  letter-spacing: 3px;
  transition: all 0.4s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #1d5f83;
  }
`

export const MenuDrilldownItem = styled.li`
  counter-increment: drilldown-items;
  position: relative;
  padding: 0.5rem;

  ${MenuLink} {
    color: #fefefe !important;
    -webkit-text-stroke: 0 !important;
    -webkit-text-fill-color: #fefefe !important;
  }

  :last-of-type {
    margin-bottom: 0;
  }

  :before {
    display: block;
    content: "0" counter(drilldown-items);
    position: absolute;
    left: -2rem;
    top: 1rem;
    color: #fefefe;
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 700;
  }
`

export const MenuDrilldown = styled.ul`
  counter-reset: drilldown-items;
  list-style: none;
  background-color: #1e1e1e;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='1'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E"); */
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(101%, 0);
  transition: all 0.4s ease-in-out;

  &.open {
    transform: translate(0, 0);
  }

  @media screen and (min-width: 1280px) {
    height: 100%;
    width: 100%;
  }
`

export const MenuTopLeftWrapper = styled.div`
  overflow: hidden;
`

export const MenuTopRightWrapper = styled(Link)`
  overflow: hidden;
  position: relative;

  :hover {
    ::after {
      transform: translateX(0%);
    }
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #2497c6;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    transform: translateX(100%);
  }

  div {
    width: 100%;
    height: 100%;
  }
`

export const MenuBottomLeftWrapper = styled.div`
  overflow: hidden;
`

export const MenuBottomRightWrapper = styled.div`
  overflow: hidden;
`

export const MenuTopLeft = styled(Link)`
  height: 100%;
  display: block;
  background: #232323;
  padding: 2rem;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 400;
  font-size: 1.5rem;
  color: #2497c6;
  transform: translate(101%, 0);
  transition: all 0.4s ease-in-out;
  text-transform: lowercase;

  :hover {
    color: #fefefe;
    cursor: pointer;

    p {
      color: #fefefe;

      :before {
        background: #fefefe;
      }
    }
  }

  p {
    font-size: 1rem;
    margin: 0 0 2rem;
    color: #6a6f77;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.4s ease-in-out;

    :before {
      content: "";
      display: block;
      background: #6a6f77;
      width: 20%;
      height: 2px;
      margin-right: 1rem;
      transition: all 0.4s ease-in-out;
    }
  }
`

export const MenuTopRight = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-image: url(${MenuLogo});
  background-size: cover;
  background-position: center;
  background-repeat: none; */
  transform: translate(-101%, 0);
  transition: all 0.4s ease-in-out;
`

export const MenuBottomLeft = styled.a`
  height: 100%;
  display: block;
  background: #2c2b2c;
  padding: 2rem;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 400;
  font-size: 1.5rem;
  color: #2497c6;
  transform: translate(101%, 0);
  transition: all 0.4s ease-in-out;
  text-transform: lowercase;

  :hover {
    color: #fefefe;
    cursor: pointer;

    p {
      color: #fefefe;

      :before {
        background: #fefefe;
      }
    }
  }

  p {
    font-size: 1rem;
    margin: 0 0 2rem;
    color: #6a6f77;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.4s ease-in-out;

    :before {
      content: "";
      display: block;
      background: #6a6f77;
      width: 20%;
      height: 2px;
      margin-right: 1rem;
      transition: all 0.4s ease-in-out;
    }
  }
`

export const MenuBottomRight = styled.div`
  width: 100%;
  height: 100%;
  background: #232323;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(0, -101%);
  transition: all 0.4s ease-in-out;

  p {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 400;
    font-size: 2rem;
    max-width: 90%;
    margin: 0 auto;
    color: #6a6f77;
    text-transform: lowercase;
    text-align: center;
  }
`

export const MenuClose = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 2rem;
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 400;
  font-size: 3rem;
  line-height: 0.45;
  color: #fefefe;
  background-color: transparent;
  border: none;
  box-shadow: none;
  z-index: 99;
  opacity: 0;
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;

  @media (min-width: 1024px) {
    right: 2.25rem;
  }

  :hover {
    cursor: pointer;
    transform: rotate(0);
  }
`

export const MenuWrapper = styled.nav`
  min-height: 640px;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  transition: all 0.4s ease-in-out;
  pointer-events: none;

  @media screen and (min-width: 1280px) {
    height: 100vh;
    grid-template-columns: 20% 30% 50%;
    grid-template-rows: 60% 40%;
    grid-template-areas: "topleft topright right" "bottomleft bottomright right";
  }

  ${MenuRight} {
    grid-column: 1fr;
    grid-row: 1fr;

    @media screen and (min-width: 1280px) {
      grid-area: right;
      position: relative;
    }
  }

  ${MenuTopLeftWrapper} {
    display: none;
    pointer-events: none;

    @media screen and (min-width: 1280px) {
      display: initial;
      grid-area: topleft;
    }
  }

  ${MenuTopRightWrapper} {
    display: none;
    pointer-events: none;

    @media screen and (min-width: 1280px) {
      display: initial;
      grid-area: topright;
    }
  }

  ${MenuBottomLeftWrapper} {
    display: none;
    pointer-events: none;

    @media screen and (min-width: 1280px) {
      display: initial;
      grid-area: bottomleft;
    }
  }

  ${MenuBottomRightWrapper} {
    display: none;
    pointer-events: none;
    
    @media screen and (min-width: 1280px) {
      display: initial;
      grid-area: bottomright;
    }
  }

  &.active {
    pointer-events: all;

    ${MenuClose} {
      opacity: 1;
    }

    ${MenuMainMenu},
    ${MenuTopLeft},
    ${MenuTopRight},
    ${MenuBottomLeft},
    ${MenuBottomRight} {
      transform: translate(0, 0%);
      pointer-events: all;
    }
  }
`
